<template>
	<div class="page">
		<van-nav-bar title="选妃" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<swiper class="banner_swiper" :options="bannerSwiperOption">
			  <swiper-slide v-for="(v,key) in xuanfeidata.img_url" :key="key">
			    <van-image class="banner_img" round :src="v">
			      <template v-slot:loading>
			        <van-loading type="circular"/>
			      </template>
			    </van-image>
			  </swiper-slide>
			</swiper>
		</div>
		
		<div class="movie-video" v-if="xuanfeidata.video">
		  <p class="name">认证视频</p>
		      <video
				style="width: 100%"
		        ref="videoPlayer"
		        :src="videoSource"
		        @play="onPlay"
		        @pause="onPause"
		        controls
		      ></video>
		  
		</div>
		 
		<div class="box2" v-if="this.userInfo.ishow == 1">
			<p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
			<p class="name">价格：{{ this.xuanfeidata.price }}</p>
			<p class="name" v-if="xuanfeidata.qq">QQ：{{ this.xuanfeidata.qq }}</p>
			<p class="name" v-if="xuanfeidata.chat">微信：{{ this.xuanfeidata.chat }}</p>
			<p class="name" v-if="xuanfeidata.mobile">电话：{{ this.xuanfeidata.mobile }}</p>
			<p class="name" v-if="xuanfeidata.kefu">客服：{{ this.xuanfeidata.kefu }}</p>
			<p v-html="xuanfeidata.content" class="htt"></p>
			
			<p class="title">{{ this.xuanfeidata.vo_title }}</p>
			<!--<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />-->
			<!--<van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">预约</van-button>-->
		</div>
		<div class="box2" v-if="this.userInfo.ishow == 0">
			<p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
			<p class="name">价格：{{ this.xuanfeidata.price }}</p>
			<p class="name" v-if="xuanfeidata.kefu">客服：{{ this.xuanfeidata.kefu }}</p>
			<p class="name" @click="$router.push({path:'/Level'});" style="background-color: #b700d4; border-radius: 5px; padding: 5px; color: #fff;">升级会员</p>
			<p v-html="xuanfeidata.content" class="htt"></p>
			
			<p class="title">{{ this.xuanfeidata.vo_title }}</p>
			<!--<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />-->
			<!--<van-button round="true" @click="yuyue" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">预约</van-button>-->
		</div>
	</div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
	data() {
		return {
			userInfo: [],
			xuanfeidata: [],
			content: '<div><h1>Hello, uniapp rich-text!</h1><p>This is a simple rich text example.</p></div>',
			cover:"",
			videoSource:'',
			banners: [{}],
			nowPlayVideoUrl: "",
			bannerSwiperOption: {
			  
			  loop: true, //是否循环轮播
			  speed: 1000, //切换速度
			  observer: true, //修改swiper自己或子元素时，自动初始化swiper
			  observeParents: true, //修改swiper的父元素时，自动初始化swiper
			  //自动轮播
			  autoplay: {
			  delay: 2000,
			  disableOnInteraction: false,
			  },
			  //设置slider容器能够同时显示的slides数量
			  slidesPerView: 1,
			  //左右切换
			  navigation: {
			  nextEl: ".swiper-button-next",
			  prevEl: ".swiper-button-prev",
			  },
			  //分页器
			  pagination: {
			  el: ".swiper-pagination",
			  clickable: true, // 允许点击小圆点跳转
			  },
			  //设置轮播样式,此处为3d轮播效果
			  effect: "coverflow",
			  coverflowEffect: {
			  rotate: 30, // 旋转的角度
			  stretch: 10, // 拉伸 图片间左右的间距和密集度
			  depth: 60, // 深度 切换图片间上下的间距和密集度
			  modifier: 2, // 修正值 该值越大前面的效果越明显
			  slideShadows: true, // 页面阴影效果
			  },
			}
		};
	},
	methods: {
		back() {
			this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
		},
		gopay(){
			
		},
		    playOrPause() {
		      if (this.isPlaying) {
		        this.$refs.videoPlayer.pause();
		      } else {
		        this.$refs.videoPlayer.play();
		      }
		    },
		    onPlay() {
		      this.isPlaying = true;
		    },
		    onPause() {
		      this.isPlaying = false;
		    },
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
				this.nowPlayVideoUrl = this.xuanfeidata.video;
				this.videoSource = this.xuanfeidata.video;
				this.cover = this.xuanfeidata.vod_pic;
				//let videos = document.getElementById('my-video');
				//videos.poster = this.xuanfeidata.vod_pic;
				//this.getVideo();
			});
		},
		getVideo() {
		  this.player = videojs("my-video",  {
		    height:"200px",
		    preload: "auto", // 预加载
		    controls: true,  // 显示播放的控件
		    multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
		  });
		  this.player.src([{
		    src: this.nowPlayVideoUrl,
		    type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
		  }]);
		
		},
		getUserInfo(){
		  this.$http({
		    method: 'get',
		    url: 'user_info'
		  }).then(res=>{
		    if(res.code === 200){
		        this.userInfo = res.data;
		       
		        if(this.userInfo.status !== 1){
		          this.$toast("账号下线");
		          localStorage.clear()
		          this.$router.push({path:'/Login'})
		        }
		    }else if(res.code ===401){
		      this.$toast(res.msg);
		    }
		  })
		},
		yuyue() {
			this.$toast('请联系顾问或接待员');
		}
	},
	created() {
		this.getUserInfo();
		this.getxuanfeidata();
		
		
		
	}
};
</script>

<style>
.video-js {
	width: 100%;
	  /*height: 420px;*/
	font-size: 24px;
}
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
}
.box2 {
	width: 95%;
	margin: 0 auto;
	padding-bottom: 6.25rem;
}
.name {
	font-size: 1.025rem;
}
.htt img{width: 100%;}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
</style>
